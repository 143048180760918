// PARTIAL : CMS Header Navigation

// Import Standard Tools
@import 'global/feature-tools';

// Import Components
@import 'components/overlays';
@import 'components/cms/modals';

nav.main {

	position: fixed;
	top: $header-line-height;
	left: 0;
	z-index: $z-index-drawer;

	width: 20em;
	height: 100%;

	background-color: transparent;

	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);

	-webkit-transition: ease-in-out .3s;
	transition: transform .3s;

	&:before {
		// adds drawer background
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 20em;
		background: $white;
		z-index: $z-index-behind;

		box-shadow:
			0 2px 4px -1px rgba(0,0,0,.2),
			0 4px 5px 0 rgba(0,0,0,.14),
			0 1px 10px 0 rgba(0,0,0,.12)
		;
	}

	&.open {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

		.dropdown {
			&:not(:first-child) {
				position: relative;
				.menu {
					top: -.382em;
				}
			}

			&:nth-child(n+7) {
				.menu {
					top: initial;
					bottom: -.382em;
				}
			}

			&.site_management_category {
				position: relative;
				.menu {
					bottom: -3.5em;
				}
			}

			.badge {
				display: inline;
				font-size: 0.6em;
				background-color: #eee;
				padding: 2px;
				border-radius: 6px;
				border: 1px solid #444444;
				color: #444444;
				font-weight: bold;
				margin-left: 0px;
				vertical-align: top;
			}
		}
	}

	> div {

		@extend %flexbox;
		@include flex-direction(column);

		section:first-of-type {
			.menu {
				padding-top: 0;
				margin-top: 0;
			}
		}

		.dropdown {
			@extend %flexbox;
			@include flex-direction(row);

			margin: 0;
			width: 20em;
			cursor: pointer;

			a {
				text-decoration: none;
			}

			header {

				@include flex(0, 1, 20em);

				&:active {
					+ .menu {
						display: none;
					}
				}

				a {
					@extend %flexbox;
					@include align-items(center);

					padding: 0 1em 0 1.382em;
					line-height: 3em;
					color: $dropdown-font-color;

					svg.icon {
						fill: $dropdown-font-color;
						border: none;

						&.next {
							margin-left: auto;
						}
					}

					span {
						@extend %flex;
						color: $text-color;
					}

					&.supplies {
						> svg {
							width: 1.5em;
							height: 1.5em;
							margin-left: -0.1em;
						}

						> span {
							flex: unset;
						}

						> .supply-lists-title {
							padding-left: 0.13em;
						}
					}
				}

				.branded-app-link-title {
					margin-right: 0 !important;
				}
			}

			svg.icon {
				width: 1.5em;
				height: 1.5em;
				vertical-align: middle;

				// override legacy code
				font-size: 1em;
			}

			span {
				margin: 0 1em;
			}

			.menu {
				// hide dropdown menus by default
				@include hide;
				@include flex-direction(column);

				position: absolute;
				left: 100%;
				width: auto;
				padding: .382em 0;
				white-space: nowrap;

				a {
					// Needs to be block element for IE, otherwise flyouts don't show up in a column
					display: block;
					line-height: 3em;
					padding: 0 .382em 0 1em;
					color: $dropdown-font-color;
					background: $dropdown-bg-color;

					svg.icon {
						fill: $dropdown-font-color;
					}
					span {
						color: $text-color;
					}

					&:hover {
						color: $dropdown-hover-color;
						text-decoration: none;
						background: $dropdown-hover-color;
						box-shadow: inset .382em 0 0 0 $dropdown-hover-color;

						svg.icon {
							fill: $dropdown-bg-color;
							color: $white;
						}
						span {
							color: $dropdown-bg-color;
						}

						.badge {
							color: $white;
							background-color: $dropdown-hover-color;
							border: 1px solid $white;
						}
					}
				}

			}

			&:hover {

				background: $dropdown-bg-color;
				text-decoration: none;
				box-shadow: inset .382em 0 0 0 $dropdown-hover-color;

				svg.icon {
					fill: $dropdown-hover-color;

					// override legacy code
					border: none;
					background: transparent;
				}
				span {
					color: $dropdown-hover-color;
				}

				@include min-screen-size(medium) {

					.menu {
						@include show(flex);
						background: $dropdown-bg-color;
					}
				}

				.supplies .badge {
					color: $red;
					border-color: $red;
				}
			}

		}

	}	

}
