// CMS Core Layout

// Dev mode activates various helpers to make life easier for you
// Will display a box at the bottom of the view that shows various helpful bits
$devmode: false;

// Blazer Core Assets
@import 'blazer-core';

// Import Custom Theme
// Custom themes override the default theme
// To create a template, use the default theme as a base
//
// Suggestion: Only specify one theme at a time, unless you want to
// create "mini-themes" that only apply to specific elements.
@import 'global/theme';

.edlio.cms {
	@include blazer-init;

	@import 'partials/header';
	@import 'partials/navigation';
	@import 'partials/footer';
	@import 'partials/ada';

	@import 'legacy/global';

	main {
		padding-top: $header-line-height;
		// height calculated by height of viewport, footer
		min-height: calc(100vh - 3.2em);

		// Container for feature UI html
		> .content {
			@extend %width-constraint;
		}

		> .content,
		.app {
			padding-top: 2em;

			.sidebar {
				@include layout-cell(one-quarter, -1);

				&.right {
					@include layout-cell(one-quarter, 99);
				}
			}

			.bar {
				@include flexbox;
				background: transparent;
			}

		}

		.workspace {
			margin-bottom: 0;
		}
	}

	// CMS-specific UI components and overrides of blazer components
	@import 'components/secondary-header';
	@import 'components/bars';

	// Add blue checkmark to selected items in edlio-select menus
	edlio-select,
	.edlio-select {
		item.selected,
		.selected.item {
			svg.icon {
				margin-left: 1em;
				fill: $blue;
			}
		}
		.item:hover {
			color: $blue;
		}
	}

	// override CMS header and paragraph styles in tinymce
	.tox-collection__group {
		h1, h2, h3, h4, p { margin-bottom: 0; }
	}

	//hide ChurnZero Success Panel's original button
	#czChatButtonWrapper {
		display: none !important;
	}

	@include max-screen-size(small) {
		// hide breadcrumbs on small screens
		.breadcrumb:not(.converted) {
			@include hide;
		}

		// Make buttons expand to full width on small screens
		.button {
			&:not(.icon),
			&.labelled.icon {
				display: block;
				margin-right: 0;
			}
		}
	}
}

.flex-address {
	display: flex;
	justify-content: space-between;
}

.address {
	align-self: center;
}

.edlio.cms .city-state-zip {
	width: 30%;
}

.require {
	border: 1px solid #DF7934 !important;
	background: #FAECE2 !important;
}

.edlio.cms .modalAddress {
	min-height: 0;
	margin-bottom: 0;
	padding: 0;
	line-height: 0;
}

.addressInput {
	width: 100% !important;
}

.foot {
	width: 100%;
}

.skip-to-main-content {
  z-index: 1;
  width: 1px;
  height: 1px;
  padding: 0.2em;
  display: block;
  overflow: hidden;
  position: absolute;
  background-color: $grey-7;
  text-decoration: underline !important;
  clip: rect(0 0 0 0);

  &:focus {
    width: auto;
    height: auto;
    clip: auto;
  }
}

/* Start Global */
.flex-row {
	display: flex;
}

.flex-vertical-center {
	align-items: center;
}

.flex-grow {
	flex: 1;
}

img.profile-photo {
	object-fit: cover;
}

.privacy_policy {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 0px;
    padding-bottom: 0px;

    a {
        color: #535D67 !important;
        font-family: 'Open Sans' !important;
        text-decoration: none !important;
        font-size: 13px;
    }
}
